@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Philosopher:wght@400;700&family=Raleway:wght@100;200;300;400;500;600;700&family=Kurale&display=swap");

* {
  font-family: "Philosopher", sans-serif;
  font-family: "Raleway", sans-serif;
}

.reOverlay .reOverlay__modalWrapper .reOverlay__modalContainer {
  background-color: white;
  border-radius: 18px;
}

.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  font-size: 100px;
  background: #ffcf78;
  color: white;
  cursor: pointer;
  border-radius: 100px;
  border: none;
}

.back-to-top:hover {
  background: #bf6c7d;
}

.swiper-container {
  width: 480px;
}

@media screen and (min-width: 320px) {
  .swiper-container {
    width: 320px;
  }
}

@media screen and (min-width: 640px) {
  .swiper-container {
    width: 640px;
  }
}

@media screen and (min-width: 768px) {
  .swiper-container {
    width: 768px;
  }
}
